import React from "react"
import SEO from "../components/utility/SEO"
import {makeStyles} from "@material-ui/core/styles";
import ImageDialog from "../components/ImageDialog";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  imageDialog: {
    width: '100%',
    maxWidth: 'unset'
  }
}));

const Product = ({pageContext}) => {
  const title = Object.keys(pageContext)[0]
  const productData = pageContext[Object.keys(pageContext)].map((item) => {
    return item.node
  })

  const classes = useStyles();
  return (
    <>
      <SEO title="Προϊόντα"/>
      <Container maxWidth={"lg"}>
        <Typography variant='h5' style={{paddingLeft: 24}}>{title}</Typography>
        <Grid container justify={"space-around"} style={{marginBottom: 8}}>
          {productData && productData.map((product, index) => {
            return (
              <Grid item style={{padding: 24}} xs={12} md={6} key={index}>
                <ImageDialog
                  image={product.data.image}
                  text={product.data.title}
                  key={index}
                  className={classes.imageDialog}/>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}

export default Product
